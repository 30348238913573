import ApiClient from "@/api/clients/ApiClient";
import Locales from "@/utils/locales";

export default class MosaiqClient extends ApiClient {

  constructor(path: string, apiKey: string) {
    const MOSAIQ_PORT = process.env.VUE_APP_MOSAIQ_PORT ? ":" + process.env.VUE_APP_MOSAIQ_PORT : "";
    super({
      baseURL: process.env.VUE_APP_MOSAIQ_HTTP_VERSION + "://" + process.env.VUE_APP_MOSAIQ_HOST + MOSAIQ_PORT + path,
      headers: {
        Accept: "application/json",
        common: {
          "X-MOSAIQ-API-KEY": apiKey,
        },
      },
      params: {
        locale: Locales.getLocaleCode(),
      },
    });
  }

  static of(): MosaiqClient {
    return new MosaiqClient(process.env.VUE_APP_MOSAIQ_API_ROUTE, process.env.VUE_APP_MOSAIQ_API_KEY);
  }

  static ofUserEndpoint(apiKey: string): MosaiqClient {
    return new MosaiqClient(process.env.VUE_APP_MOSAIQ_UE_API_ROUTE, apiKey || process.env.VUE_APP_MOSAIQ_API_KEY);
  }
}