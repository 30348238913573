export default class scriptLoader {

    static loadJs(src, id, customAttributes) {
        const existingScript = document.getElementById(id);
        return new Promise(function (resolve, reject) {
            if (!existingScript) {
                const script = document.createElement('script');
                script.src = src;
                script.id = id
                if (customAttributes) {
                    for (const customAttrName in customAttributes) {
                        console.log("systempay script attribute: " + customAttrName + ": " + customAttributes[customAttrName]);
                        script.setAttribute(customAttrName, customAttributes[customAttrName])
                    }
                }
                script.onload = () => resolve(script);
                script.onerror = () => reject(new Error(`Script load error for ${src}`));
                document.head.append(script);
            } else {
                resolve(id + 'allreadyLoaded');
            }
        });
    }

    static loadStyle(src, id) {
        const existingScript = document.getElementById(id);
        return new Promise(function (resolve, reject) {
            if (!existingScript) {
                const element = document.createElement('link')
                element.setAttribute('rel', 'stylesheet')
                element.setAttribute('type', 'text/css')
                element.setAttribute('href', src)
                element.setAttribute('id', id)
                element.onload = () => resolve(src)
                element.onerror = () => reject(src)
                document.head.appendChild(element)
            } else {
                resolve(id + 'allreadyLoaded');
            }
        });
    }

    static deepMerge(obj1: any, obj2: any): any {
        const merged = { ...obj1 };

        for (const key in obj2) {
            if (typeof obj2[key] === 'object' && obj2[key] !== null) {
                if (key in obj1 && typeof obj1[key] === 'object' && obj1[key] !== null) {
                    // Si la clé existe dans les deux objets et est un objet, fusionner récursivement
                    merged[key] = this.deepMerge(obj1[key], obj2[key]);
                } else {
                    // Sinon, ajouter la clé et sa valeur du deuxième objet
                    merged[key] = { ...obj2[key] };
                }
            } else {
                // Si la clé n'existe pas dans le premier objet, ajouter la clé et sa valeur du deuxième objet
                merged[key] = obj2[key];
            }
        }

        return merged;
    }

    static mergeObjects(obj1: any, obj2: any): any {
        const mergedObject = this.deepMerge({...obj1}, {...obj2});
        return mergedObject;
    }

    static mergeRoutesWithoutDuplicates(arr1: any, arr2: any): any {
        const map1 = new Map<string, any>(arr1.map((route) => [route.path, route]));

        // Remplacer les entrées du premier tableau par celles du deuxième si elles existent
        for (const route of arr2) {
            map1.set(route.path, route);
        }

        // Convertir le Map fusionné en tableau
        const mergedArray = Array.from(map1.values());

        return mergedArray;
    }



    static mergeArray(array1: any, array2: any): any {
        const mergedArray = this.mergeRoutesWithoutDuplicates(array1,array2);
        return mergedArray;
    }
}
