import { Address } from "./address";
import { Price } from "./price";
import { Shipment } from "./shipment";
import { OrderStage } from "@/enum/orderStage";

export class Order {
  
  id!: string;
  reference!: string;
  orderStage!: OrderStage;
  cancellationState!: string;
  creationDate!:Date;
  eventHistory!: any;
  payment!: Payment | null;
  shipments!: Shipment[];
  billingAddress!: Address | null;
  shippingAddress!: Address | null;
  coupon!: any;
  productsPrice!: Price;
  shippingPrice!: Price;
  totalPrice!: Price;
  discountPrice!: Price;

  private constructor() {
    // instances creation should be done using of() method
  }

  public static of(data: any): Order {
    const o: Order = new Order();
    o.id = data.id;
    o.reference = data.reference;
    o.payment = Payment.of(data.payment);
    o.orderStage = data.orderStage;
    o.cancellationState = data.cancellationState;
    o.creationDate = new Date(data.creationDate);
    o.shipments = [];
    for (const shipment of data.shipments) {
      o.shipments.push(Shipment.of(shipment));
    }
    o.productsPrice = Price.of(data.productsPrice);
    o.totalPrice = Price.of(data.totalPrice);
    o.shippingPrice = Price.of(data.shippingPrice);
    o.discountPrice = Price.of(data.discountPrice);
    o.shippingAddress = data.shipments[0].address ? Address.of(data.shipments[0].address) : null;
    o.billingAddress = data.billingAddress ? Address.of(data.billingAddress) : null;
    o.eventHistory= []
    for (const event of data.eventHistory) {
      o.eventHistory.push(EventHistory.of(event, o.shipments));
    }
    Object.entries(o.eventHistory)
        .sort((a: any, b: any) => new Date(a[1].date).getTime() - new Date(b[1].date).getTime())
        .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    //o.eventHistory = EventHistory.of(data.eventHistory.all, o.shipments);
    o.coupon = data.coupon ? Coupon.of(data.coupon) : null;

    return o;
  }
}

export class Coupon {
  cartPriceRuleId!: string;
  code!: string;
  description!: string;
  id!: string;
  name!: string;

  private constructor() {
    // instances creation should be done using of() method
  }

  public static of(data: any) {
    const c = new Coupon();

    c.cartPriceRuleId = data.cartPriceRuleId;
    c.code = data.code;
    c.description = data.description;
    c.id = data.id;
    c.name = data.name;

    return c;
  }
}

export class EventHistory {
  date!:Date;
  stage!:string;
  shippedInfo?:any
  paymentInfo?:any;

  private constructor() {
    // instances creation should be done using of() method
  }

  public static of(eventHistory: any, shipments?: Shipment[]): EventHistory {
    const e = new EventHistory();
    e.date = new Date(eventHistory.instant)

    if(eventHistory.type === 'PAYMENT'){
      e.paymentInfo ={date: new Date(eventHistory.payment.date), method:eventHistory.payment.method, transactionReference:eventHistory.payment.transactionReference}
      e.stage = "PAYMENT"
    }else{
      e.stage = eventHistory.stage
    }

    if ( e.stage==="SHIPPED") {
      const trackings: any = [];
      shipments?.forEach((s: Shipment) => {
        if (s?.trackingInfo?.trackingNumber && s?.trackingInfo?.trackingUrl) {
          trackings.push({ number: s?.trackingInfo?.trackingNumber, url: s?.trackingInfo?.trackingUrl });
        }
      });

      if (trackings.length) {
        e.shippedInfo = { date: new Date(eventHistory.instant), trackings };
      }
    }

    return e;
  }
}

export class Payment {
  date!:Date;
  reason!:string;
  status!:string;
  paymentMethod!: string;
  transactionNumber!: string;

  private constructor() {
    // instances creation should be done using of() method
  }

  public static of(data: any): Payment | null {
    if (data === null) {
      return null;
    }

    const b: Payment = new Payment();
    b.date = new Date(data.date);
    b.paymentMethod = data.method;
    b.reason = data.reason;
    b.status = data.status
    b.transactionNumber = data.transactionReference;
    return b;
  }
}
