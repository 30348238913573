import QorusClient from "@/api/clients/QorusClient";
import { Order } from "@/models/order";
import { Page } from "@/models/page";
import { AxiosRequestConfig } from "axios";


/**
 *  Qorus API about orders
 */
export default class OrderService {
    private qorusClient = QorusClient.of();

    static of(): OrderService {
        return new OrderService();
    }

    getAll(query): Promise<Page<Order>> {
        //v1/products?category=<category>
        //v1/products/categories/<category>
        const defaultSort = "paymentDate.desc";
        const sort = query.sort?.split(".")[0] || defaultSort.split(".")[0];
        const sortOrder = query.sort?.split(".")[1] || defaultSort.split(".")[1];
        return this.qorusClient.get("orders/", {
            params: {
                size: query.itemsPerPage || 1,
                page: query.page - 1 || 0,
                sort: sort + "," + sortOrder,
            },
        });
    }

    getByReference(orderReference: string) {
        return this.qorusClient.get("orders/" + orderReference)
            .then(
                (data) => {
                    return Order.of(data);
                },
                (data) => {
                    throw data;
                },
            );
    }

    getById(orderId: string) {
        return this.qorusClient.get("orders/id/" + orderId)
            .then(
                (data) => {
                    return Order.of(data);
                },
                (data) => {
                    throw data;
                },
            );
    }

    getCustomerOrders(query: any): Promise<Page<Order>> | unknown {
        const sort = query?.sort?.split(".")[0] || "paymentDate";
        const sortOrder = query?.sort?.split(".")[1] || "desc";
        const axiosRequestConfig: AxiosRequestConfig = {
            params: {
                size: query?.itemsPerPage || 10,
                page: query?.page - 1 || 0,
                sort: sort + "," + sortOrder,
            },
        };
        return this.qorusClient.get("orders/customer", axiosRequestConfig)
            .then(
                (data) => {
                    return data;
                },
                (data) => {
                    throw data;
                },
            );
    }

    getInvoice(orderId) {
        const axiosRequestConfig: AxiosRequestConfig = {
            responseType: "arraybuffer",
            headers: {
                "Content-Type": "application/json",
            },
        };
        return this.qorusClient.get("order-invoices/pdf/order/" + orderId, axiosRequestConfig)
            .then(
                (data) => {
                    return data;

                },
                (data) => {
                    throw data;
                },
            );
    }
}